import React, { useEffect, useState } from 'react';
import '../assets/css/HowTo.css';
import howtobanner from "../assets/images/howtobanner.jpg";
import image2 from "../assets/images/howto_sec3.jpg";
import stepguide01 from "../assets/images/Step_Image_01-Email.webp";
import stepguide02 from "../assets/images/Step_Image_02.webp";
import stepguide03 from "../assets/images/Step_Image_03.webp";
import stepguide04 from "../assets/images/Step_Image_Checkout_1.webp";
import stepguide05 from "../assets/images/Step_Image_04video.webp";

import PriceCards from '../components/PriceCards';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { loadCTABanner, loadLegals } from "../common/Common";
import Loading from "../components/Loading";
import CTABanner from "../components/CTABanner";
import EditorJS from '@editorjs/editorjs';
import { getEditorJSTools } from "../components/editor-constants";

let editor = undefined;

export default function HowTo() {
    const [activeIndex, setActiveIndex] = useState(null),
        [ctaBanner, setCtaBanner] = useState(null),
        [legals, setLegals] = useState([]),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        loadLegals()
            .then((data) => {
                setLegals(data);
            })
            .catch(() => null);

        loadCTABanner("how to")
            .then((data) => {
                setCtaBanner(data);
                setLoading(false);
            })
            .catch(() => null);
    }, []);

    const toggleFAQ = (index) => {
        editor = undefined;
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        if (!loading && !editor && activeIndex !== null) {
            try {
                const descriptionData = JSON.parse(legals[activeIndex].description);
                editor = new EditorJS({
                    holder: 'editor' + activeIndex,
                    tools: getEditorJSTools(null),
                    readOnly: true,
                    data: descriptionData,
                });
            } catch (error) {
                console.error("Invalid JSON:", legals[activeIndex]?.description);
            }
        }
    }, [activeIndex, legals, loading]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className='contact-main body-main'>
            <div className='contact-container'>
                <div className='top-banner-default'>
                    <div className='text-section series-container'>
                        <span className='bdc-txt'>Home / How To</span>
                        <span href="" className='mani-pg-name'>How to</span>
                    </div>
                    <div className='abs-slider-images'>
                        <picture>
                            <source media="(max-width: 767px)" srcSet={howtobanner} alt="/" />
                            <source media="(max-width: 1023px)" srcSet={howtobanner} alt="" />
                            <img src={howtobanner} alt="background" />
                        </picture> 
                    </div>
                </div>
                <div className='special-wrapper-howto'>
                    <div className='section-2-howto series-container'>
                        <div className='how-sec2-div1'>
                            <span className='topic'>Welcome to your Pilates Corner. Here we hope to help guide you through all the stages of your Pilates journey.</span>
                        </div>
                    </div>
                </div>
                <div className='home-container how-common-wrapper'>
                    <div className='new-flex-wrapper-cont'>
                        <div className='inner-cont'>
                            <div className='child-inner-div '>
                                <img src={image2} alt='image2' />
                            </div>
                            <div className='section-3-howto-content'>
                                <h2>Welcome to your Pilates Corner!</h2>
                                <p>
                                    Pilates is an amazing form of exercise! It is a uniquely adaptable form of strengthening
                                    & toning which uses a low resistance, high repetitions model to safely load your joints
                                    and muscles.<br /><br />
                                    Gradually progress through the different class levels from Baby Steps through
                                    to the more advanced class levels and see your body and confidence slowly transform.<br /><br />
                                    The Pilates Corner is perfect for almost everyone whether you’re looking for a low impact
                                    form of strengthening to keep your joints healthy or if you’re in search of a slower-paced
                                    strength or flexibility session to supplement your weekly training schedule, the Pilates Corner has it all.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='special-wrapper-howto'>
                    <div className='howto-sec-4-main series-container'>
                        <span className='sec-4-howto-topic'>Instructions for First Time Users</span>
                        <div className='step-guide'>
                            
                                <div className='single-flex-rw'>
                                    <div className='img-sec-4-howto'>
                                        <img src={stepguide01} alt="image" />
                                    </div>
                                    <div className='text-sec-4-howto'>
                                        <span className='sub-title'>Step 1 </span>
                                        <span className='sub-title2'>Verify your Email Address</span>
                                        <span className='sub-title3'>To sign up, you need to enter your email address and verify using the OTP sent to your email.</span>
                                    </div>
                                </div>

                                <div className='single-flex-rw'>
                                    <div className='img-sec-4-howto'>
                                        <img src={stepguide02} alt="image" />
                                    </div>
                                    <div className='text-sec-4-howto'>
                                        <span className='sub-title'>Step 2 </span>
                                        <span className='sub-title2'>Enter your Personal Details</span>
                                        <span className='sub-title3'>Enter your first name, last name, and your password to proceed with creating your account.</span>
                                    </div>
                                </div>

                                <div className='single-flex-rw'>
                                    <div className='img-sec-4-howto'>
                                        <img src={stepguide03} alt="image" />
                                    </div>
                                    <div className='text-sec-4-howto'>
                                        <span className='sub-title'>Step 3 </span>
                                        <span className='sub-title2'>Choose your Subscription</span>
                                        <span className='sub-title3'>Select your preferred subscription plan, whether it's monthly or annual.</span>
                                    </div>
                                </div>

                                <div className='single-flex-rw'>
                                    <div className='img-sec-4-howto'>
                                        <img src={stepguide04} alt="image" />
                                    </div>
                                    <div className='text-sec-4-howto'>
                                        <span className='sub-title'>Step 4 </span>
                                        <span className='sub-title2'>Checkout and Pay</span>
                                        <span className='sub-title3'>Enter your payment details to secure your subscription plan.</span>
                                    </div>
                                </div>

                                <div className='single-flex-rw'>
                                    <div className='img-sec-4-howto'>
                                        <img src={stepguide05} alt="image" />
                                    </div>
                                    <div className='text-sec-4-howto'>
                                        <span className='sub-title'>Step 5 </span>
                                        <span className='sub-title2'>All set! Stay fit!</span>
                                        <span className='sub-title3'>Enjoy over 60+ classes and seasonal courses throughout!</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className='special-wrapper2-howto'>
                    <div className='section-5-howto series-container'>
                        {legals.map((legal, index) => (
                            <div className="faq-item" key={"Legal" + index}>
                                <div
                                    className={`faq-question ${activeIndex === index ? 'open' : ''}`}
                                    onClick={() => toggleFAQ(index)}
                                >
                                    {legal.heading}
                                    <span className="arrow">
                                        {activeIndex === index ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.9201 15.05L13.4001 8.53005C12.6301 7.76005 11.3701 7.76005 10.6001 8.53005L4.08008 15.05" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M19.9201 8.94995L13.4001 15.47C12.6301 16.24 11.3701 16.24 10.6001 15.47L4.08008 8.94995" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        )}
                                    </span>
                                </div>
                                {activeIndex === index && (
                                    <div className="faq-answer textarea-description">
                                        <div id={"editor" + index} />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='special-wrapper-howto'>
                    <div className='price-howto series-container'>
                        <PriceCards />
                    </div>
                </div>
                {ctaBanner && <CTABanner ctaBanner={ctaBanner} />}
            </div>
        </div>
    );
}
