import React, {useState} from 'react';
import '../assets/css/UnsubscribePopup.css';
import CancelSubscriptionPopUp from './CancelSubscriptionSuccessPopUp';
import {postRequest} from "../routes/Routes";

const UnsubscribePopup = ({onClose, user}) => {
    const [rating, setRating] = useState(0),
        [reason, setReason] = useState(''),
        [additionalFeedback, setAdditionalFeedback] = useState(''),
        [showCancelSubscriptionSuccessPopup, setCancelSubscriptionSuccessShowPopup] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({});

    const handleRatingClick = (index) => {
        setRating(index);
    };

    const handleCancelSubscriptionSuccessOpenPopup = () => {
        setLoading(true);
        let values = {
            cancel_rating: rating,
            cancel_reason: reason,
            cancel_note: additionalFeedback,
            token: user.token,
            endpoint: '/api/user-pricing-package/cancel'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setCancelSubscriptionSuccessShowPopup(true);
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    }

    const handleCancelSubscriptionSuccessClosePopup = () => {
        setLoading(false);
        setCancelSubscriptionSuccessShowPopup(false);
    }

    return (
        <div className="UnsubscribePopup-popup-container">
            <div className="popup">
                <button className="close-button" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                            d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM19.2 17.875C19.5625 18.2375 19.5625 18.8375 19.2 19.2C19.0125 19.3875 18.775 19.475 18.5375 19.475C18.3 19.475 18.0625 19.3875 17.875 19.2L15 16.325L12.125 19.2C11.9375 19.3875 11.7 19.475 11.4625 19.475C11.225 19.475 10.9875 19.3875 10.8 19.2C10.4375 18.8375 10.4375 18.2375 10.8 17.875L13.675 15L10.8 12.125C10.4375 11.7625 10.4375 11.1625 10.8 10.8C11.1625 10.4375 11.7625 10.4375 12.125 10.8L15 13.675L17.875 10.8C18.2375 10.4375 18.8375 10.4375 19.2 10.8C19.5625 11.1625 19.5625 11.7625 19.2 12.125L16.325 15L19.2 17.875Z"
                            fill="#A6A6A6"/>
                    </svg>
                </button>
                <h2 className='UnsubscribePopup-popup-main'>We are sorry to see you go.</h2>
                <h2 className='UnsubscribePopup-popup-main'>How was your experience?</h2>
                <p className='UnsubscribePopup-popup-sub'>Rate your experience and help us become better.</p>

                <div className="star-rating">
                    {[...Array(5)].map((star, index) => {
                        index += 1;
                        return (
                            <button
                                key={index}
                                className={index <= rating ? 'on' : 'off'}
                                onClick={() => handleRatingClick(index)}
                            >
                                <span className="star">&#9733;</span>
                            </button>
                        );
                    })}
                </div>

                <p className='UnsubscribePopup-popup-sub'>Give us your feedback. This will only take a few seconds.</p>

                <div className="question">
                    <p className='UnsubscribePopup-popup-pickup-main'>Question:</p>
                    <p className='UnsubscribePopup-popup-pickup-sub'>
                        What is the reason for cancelling your subscription?
                    </p>
                    <div className='UnsubscribePopup-popup-pickup-option-container'>
                        <div>
                            <div>
                                <label className='UnsubscribePopup-popup-pickup-option'>
                                    <input
                                        type="radio"
                                        value="Don't need it right now"
                                        checked={reason === "Don't need it right now"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    Don't need it right now
                                </label>
                            </div>
                            <div>
                                <label className='UnsubscribePopup-popup-pickup-option'>
                                    <input
                                        type="radio"
                                        value="Too expensive"
                                        checked={reason === "Too expensive"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    Too expensive
                                </label>
                            </div>
                            <div>
                                <label className='UnsubscribePopup-popup-pickup-option'>
                                    <input
                                        type="radio"
                                        value="Wasn't useful to me"
                                        checked={reason === "Wasn't useful to me"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    Wasn't useful to me
                                </label>
                            </div>
                        </div>
                        <div>
                            <div>
                                <label className='UnsubscribePopup-popup-pickup-option'>
                                    <input
                                        type="radio"
                                        value="Not Interested"
                                        checked={reason === "Not Interested"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    Not Interested
                                </label>
                            </div>
                            <div>
                                <label className='UnsubscribePopup-popup-pickup-option'>
                                    <input
                                        type="radio"
                                        value="Any other reasons?"
                                        checked={reason === "Any other reasons?"}
                                        onChange={(e) => setReason(e.target.value)}
                                    />
                                    Any other reasons?
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <textarea
                    placeholder="Type message here..."
                    value={additionalFeedback}
                    onChange={(e) => setAdditionalFeedback(e.target.value)}
                    className='UnsubscribePopup-popup-pickup-textarea'
                />
                {error.error &&
                <div className="alert alert-danger" role="alert">
                    {error.errorMessage}
                </div>}
                <div className="buttons">
                    <button className="UnsubscribePopup-no-btn" onClick={onClose}>No</button>
                    <button onClick={handleCancelSubscriptionSuccessOpenPopup} className="UnsubscribePopup-yes-btn">
                        {loading ?
                            <span className="spinner-grow spinner-grow-sm" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        Yes
                    </button>
                    {showCancelSubscriptionSuccessPopup &&
                    <CancelSubscriptionPopUp onClose={handleCancelSubscriptionSuccessClosePopup}/>}
                </div>
            </div>

        </div>
    );
};

export default UnsubscribePopup;
