import React, {useEffect, useState} from 'react';
import '../assets/css/Profile.css';
import userProfile from "../assets/images/user.png";
import ProfileSeries from '../components/ProfilesSeries';
import ProfileCourses from '../components/ProfileCourses';
import ProfileUserSettings from '../components/ProfileUserSettings';
import PriceCard from '../components/PriceCards';
import BillingHistory from '../components/BillingHistory';
import {getUser} from "../auth/Auth";
import Loading from "../components/Loading";
import {loadUserPricingPackage, loadUserTransactions} from "../common/Common";

export default function Profile() {
    const [selectedTab, setSelectedTab] = useState('series');
    const [user, setUser] = useState(null),
        [userPricingPackage, setUserPricingPackage] = useState(null),
        [transactions, setTransactions] = useState([]),
        [loading, setLoading] = useState(true);

    const handleLogout = () => {
        console.log("User logged out");
    };

    useEffect(() => {
        getUser().then((data) => {
            setUser(data);
            loadUserPricingPackage(data.token).then((data) => {
                setUserPricingPackage(data);
                setLoading(false);
            }).catch(() => null);
            loadUserTransactions(data.token).then((data) => {
                setTransactions(data);
            }).catch(() => null);
        }).catch(() => {
            setUser(null);
        });
    }, []);

    const updateUser = (user) => {
        setUser(user);
    }

    const renderContent = () => {
        switch (selectedTab) {
            case 'series':
                return <ProfileSeries userPricingPackage={userPricingPackage}/>;
            case 'courses':
                return <ProfileCourses userPricingPackage={userPricingPackage}/>;
            case 'billing-history':
                return <BillingHistory user={user} userPricingPackage={userPricingPackage}
                                       transactions={transactions} updateUser={updateUser}/>;
            case 'user-settings':
                return <ProfileUserSettings user={user}/>;
            case 'pricing':
                return <div className='series-container spc-price-crd'>
                    <PriceCard/>
                </div>;
            default:
                return null;
        }
    };

    if (user && !loading) {
        return (
            <div className='body-main'>
                <div className='profile-cover'>
                    <div className='section'>
                        <img className='profile-img' src={user.photo ? user.photo : userProfile} alt="Profile"/>
                    </div>
                </div>
                <div className='profile-section-wrap'>
                    <div className='section-two'>
                        <h2 className='section-title'>{user.first_name} {user.last_name}</h2>
                        <p className='section-sub-title'>{user.email}</p>
                    </div>
                    <div className='tab-container'>
                        <select
                            className='tab-selections mobile-only'
                            value={selectedTab}
                            onChange={(e) => {
                                e.target.value === "logout" ? handleLogout() : setSelectedTab(e.target.value);
                            }}
                        >
                            <option value="series">Classes</option>
                            <option value="courses">Courses</option>
                            <option value="billing-history">Billing History</option>
                            <option value="user-settings">User Settings</option>
                            {/*<option value="pricing">Subscriptions</option>*/}
                            <option value="logout">Logout</option>
                        </select>
                        <div className='tab-view desktop-only'>
                            <button className={`tab-item ${selectedTab === 'series' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('series')}>Classes
                            </button>
                            <button className={`tab-item ${selectedTab === 'courses' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('courses')}>Courses
                            </button>
                            <button className={`tab-item ${selectedTab === 'billing-history' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('billing-history')}>Billing History
                            </button>
                            <button className={`tab-item ${selectedTab === 'user-settings' ? 'active' : ''}`}
                                    onClick={() => setSelectedTab('user-settings')}>User Settings
                            </button>
                            {/*<button className={`tab-item ${selectedTab === 'pricing' ? 'active' : ''}`}*/}
                            {/*        onClick={() => setSelectedTab('pricing')}>Subscriptions*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
                <div className='render-content'>{renderContent()}</div>
            </div>
        );
    } else {
        return (
            <Loading/>
        );
    }
}
