import React, { useEffect, useState } from 'react';

import '../assets/css/Series.css';
import mainbanner01 from "../assets/images/coursesMain.jpg";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { loadCourses, loadCoursesOnCategories, loadCTABanner } from "../common/Common";
import Loading from "../components/Loading";
import CTABanner from "../components/CTABanner";

function Courses() {
    const [ctaBanner, setCtaBanner] = useState(null),
        [courses, setCourses] = useState([]),
        [categoryCourses, setCategoryCourses] = useState([]),
        [loading, setLoading] = useState(true);

    useEffect(() => {
        loadCTABanner("courses").then((data) => {
            setCtaBanner(data);
        }).catch(() => null);
        loadCourses().then((data) => {
            setCourses(data);
        }).catch(() => null);
        loadCoursesOnCategories().then((data) => {
            setCategoryCourses(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    const courseAll = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: "10px",
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: true,
        prevArrow: <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <circle cx="35" cy="35" r="35" transform="matrix(-1 0 0 1 70 0)" fill="#687362" />
            <path
                d="M19.2929 35.7071C18.9024 35.3166 18.9024 34.6834 19.2929 34.2929L25.6569 27.9289C26.0474 27.5384 26.6805 27.5384 27.0711 27.9289C27.4616 28.3195 27.4616 28.9526 27.0711 29.3431L21.4142 35L27.0711 40.6569C27.4616 41.0474 27.4616 41.6805 27.0711 42.0711C26.6805 42.4616 26.0474 42.4616 25.6569 42.0711L19.2929 35.7071ZM51 36H20V34H51V36Z"
                fill="white" />
        </svg>,
        nextArrow: <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
            <circle cx="35" cy="35" r="35" fill="#687362" />
            <path
                d="M50.7071 35.7071C51.0976 35.3166 51.0976 34.6834 50.7071 34.2929L44.3431 27.9289C43.9526 27.5384 43.3195 27.5384 42.9289 27.9289C42.5384 28.3195 42.5384 28.9526 42.9289 29.3431L48.5858 35L42.9289 40.6569C42.5384 41.0474 42.5384 41.6805 42.9289 42.0711C43.3195 42.4616 43.9526 42.4616 44.3431 42.0711L50.7071 35.7071ZM19 36H50V34H19V36Z"
                fill="white" />
        </svg>,
        responsive: [
            {
                breakpoint: 1025, // Below 768px screen width
                settings: {
                    slidesToShow: 3, // Show 1 slide at a time
                    slidesToScroll: 1,
                    centerMode: true, // Optional: Turn off centering for mobile
                    centerPadding: "20px", // Optional: Remove padding on mobile
                    dots: false,
                    arrows: true,
                }
            }, {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "10px",
                    dots: false
                }
            }
            , {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: "10px",
                    dots: false
                }
            }
        ]
    };

    const courseList = (courses) => {
        let body = [];
        courses.forEach((course, index) => {
            body.push(
                <div className='series-slider-content-details courses-slider-content-details'
                    key={course.name + "" + course.id + "" + index}>
                    <div className='series-slider-home-image courses-slider-home-image'>
                        <iframe src={course.link + "?&background=1&autoplay=0"} frameBorder="0"
                            allow="fullscreen; muted" />
                    </div>
                    <h2 className='spc-single-nm'>{course.name}</h2>
                    <div
                        className='series-slider-content-details-topic courses-slider-content-details-topic'>
                        <h3>{course.instructor}</h3> <h3>|</h3>
                        <p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M14.6666 8.00016C14.6666 11.6802 11.6799 14.6668 7.99992 14.6668C4.31992 14.6668 1.33325 11.6802 1.33325 8.00016C1.33325 4.32016 4.31992 1.3335 7.99992 1.3335C11.6799 1.3335 14.6666 4.32016 14.6666 8.00016Z"
                                    stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round" />
                                <path
                                    d="M10.4734 10.1202L8.40675 8.88684C8.04675 8.6735 7.75342 8.16017 7.75342 7.74017V5.00684"
                                    stroke="#9D9D9D" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                            {course.time} minutes
                        </p>
                    </div>
                </div>
            );
        });
        return body;
    }

    if (!loading) {
        return (
            <div className='body-main'>
                <div className='top-banner-default'>
                    <div className='text-section series-container'>
                        <span className='bdc-txt '>
                            Home / Courses
                        </span>
                        <span href="" className='mani-pg-name'>Courses</span>
                    </div>
                    <div className='abs-slider-images'>
                        <picture>
                            <source media="(max-width: 767px)" srcset={mainbanner01} alt="/" />
                            <source media="(max-width: 1023px)" srcset={mainbanner01} alt="" />
                            <img src={mainbanner01} alt="background" />
                        </picture>
                    </div>
                </div>
                {/* <div className='series-main-wrapper series-container'>
                    <div className='slider-set-default'>
                        <span className='slider-set-category'>All Courses</span>
                        <div className='series-slider-wrapper'>
                            <Slider {...courseAll}>
                                {courseList(courses)}
                            </Slider>
                        </div>
                    </div>
                    {categoryCourses.map((categoryCourse, index) => (
                        <div className='slider-set-default' key={"CategoryCourse" + index}>
                            <span className='slider-set-category'>{categoryCourse.category.name}</span>
                            <div className='series-slider-wrapper'>
                                <Slider {...courseAll}>
                                    {courseList(categoryCourse.courses)}
                                </Slider>
                            </div>
                        </div>
                    ))}
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                </div> */}
                <div className='series-main-wrapper series-container'>
                    <div className='slider-set-default'>
                        {/* <span className='slider-set-category'>All Courses</span> */}
                        {/* <div className='series-slider-wrapper'>
                            {courses && courses.length > 0 ? (
                                 <Slider {...courseAll}>
                                    {courseList(courses)}
                                 </Slider>

                            ) : (
                                <div className='coming-soon'>
                                    <h3>Coming soon...111</h3>
                                    <p>We’re working on bringing you a curated selection of new courses and classes. Stay tuned as we update this page with fresh opportunities to learn and grow. Coming soon!</p>

                                </div>
                            )}
                        </div> */}
                    </div>
                    {categoryCourses && categoryCourses.length > 0 ? (
                        <>
                            {categoryCourses.map((categoryCourse, index) => (
                                <div className='slider-set-default' key={"CategoryCourse" + index}>
                                    <span className='slider-set-category'>{categoryCourse.category.name}</span><div className='series-new-image-box'>
                                        <div className='image-ser-sub-item'>
                                            <img src={categoryCourse.category.image} alt={categoryCourse.category.name} />
                                            <p className='image-description'>
                                                {categoryCourse.category.description}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='series-slider-wrapper separate-slider'>
                                        {categoryCourse.courses && categoryCourse.courses.length > 0 ? (
                                            <Slider {...courseAll}>
                                                {courseList(categoryCourse.courses)}
                                            </Slider>
                                        ) : (
                                            <div className='coming-soon'>
                                                <h3>Coming soon...</h3>
                                                <p>We’re working on bringing you a curated selection of new courses and classes. Stay tuned as we update this page with fresh opportunities to learn and grow. Coming soon!</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className='coming-soon'>
                            <h3>Coming soon...</h3>
                            <p>We’re working on bringing you a curated selection of new courses and classes. Stay tuned as we update this page with fresh opportunities to learn and grow. Coming soon!</p>
                        </div>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
                {ctaBanner &&
                    <CTABanner ctaBanner={ctaBanner} />
                }
            </div>
        )
    } else {
        return (
            <Loading />
        );
    }
}

export default Courses
