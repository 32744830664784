import React, {useEffect, useState} from 'react';
import '../assets/css/Profile.css';
import DatePicker from 'react-datepicker';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {format, fromUnixTime} from 'date-fns';
import '../assets/css/Series.css';
import visa from "../assets/images/visa.svg";
import master from "../assets/images/master.svg";
import "react-datepicker/dist/react-datepicker.css";
import CardRemovedPopup from './CardRemoved';
import SetupPayment from './AddNewPaymentMethod';
import EditNewPaymentMethod from './EditNewPaymentMethod';
import Loading from "./Loading";
import {postRequest} from "../routes/Routes";
import {updateUser as updateUserCookie} from "../auth/Auth";
import {toast} from "react-toastify";
import {loadStripeCustomerPaymentMethods} from "../common/Common";
import CancelSubscriptionPopUp from "./CancelSubscriptionPopUp";

export default function BillingHistory({user, userPricingPackage, transactions, updateUser}) {
    const [selectedOption, setSelectedOption] = useState(user.alternate_email ? 'alternate' : 'account'),
        [selectedCard, setSelectedCard] = useState(null),
        [removeCard, setRemoveCard] = useState(null),
        [selectedDate, setSelectedDate] = useState(null),
        [isCalendarOpen, setIsCalendarOpen] = useState(false),
        [searchInvoice, setSearchInvoice] = useState(""),
        [selectedStatus, setSelectedStatus] = useState(""),
        [showPopup, setShowPopup] = useState(false),
        [showEditPopup, setEditShowPopup] = useState(false),
        [showRemoveCardPopup, setRemoveCardShowPopup] = useState(false),
        [showConfirmRemoveCardPopup, setConfirmRemoveCardShowPopup] = useState(false),
        [showCancelSubscriptionPopup, setCancelSubscriptionShowPopup] = useState(false),
        [loading, setLoading] = useState(false),
        [loadingRenew, setLoadingRenew] = useState(false),
        [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true),
        [loadingRemoveCard, setLoadingRemoveCard] = useState(false),
        [paymentMethods, setPaymentMethods] = useState(null),
        [filteredTransactions, setFilteredTransactions] = useState(transactions),
        [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null),
        dateFormat = 'dd MMM yyyy';

    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    const handleSwitchClick = () => {
        setShowConfirmationPopup(true);
    };

    const confirmSwitchPlan = () => {
        setShowConfirmationPopup(false);
        switchSubscription();
    };

    const cancelSwitchPlan = () => {
        setShowConfirmationPopup(false);
    };

    useEffect(() => {
        loadStripeCustomerPaymentMethods(user.token).then((data) => {
            setPaymentMethods(data.paymentMethods);
            if (data.stripeCustomer.invoice_settings.default_payment_method) {
                setSelectedCard(data.stripeCustomer.invoice_settings.default_payment_method);
            } else if (data.paymentMethods && data.paymentMethods.data.length > 0) {
                setSelectedCard(data.paymentMethods.data[0].id);
            }
            setLoadingPaymentMethods(false);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        const newFilteredItems = transactions.filter((item) => {
            const invoiceMatch = item.reference.includes(searchInvoice);
            const statusMatch = selectedStatus ? item.status === selectedStatus : true;
            const dateMatch = selectedDate ? format(item.created_at, "MMMM dd, yyy") === format(selectedDate, "MMMM dd, yyy") : true;
            return invoiceMatch && statusMatch && dateMatch;
        });
        setFilteredTransactions(newFilteredItems);
    }, [searchInvoice, selectedStatus, selectedDate]);

    const handleClick = () => {
        setIsCalendarOpen(prevState => !prevState);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setIsCalendarOpen(false);
    };

    const handleOpenPopup = () => setShowPopup(true);
    const handleClosePopup = () => setShowPopup(false);

    const handleEditOpenPopup = () => setEditShowPopup(true);
    const handleEditClosePopup = () => setEditShowPopup(false);

    const handleRemoveCardOpenPopup = (card, paymentMethodId) => {
        setLoadingRemoveCard(true);
        let values = {
            payment_method_id: paymentMethodId,
            token: user.token,
            endpoint: '/api/user/remove-stripe-customer-payment-method'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setLoadingRemoveCard(false);
                    setPaymentMethods(response.data.paymentMethods);
                    setRemoveCard(card);
                    setRemoveCardShowPopup(true);
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    }

    const handleRemoveCardClosePopup = () => {
        if (paymentMethods && paymentMethods.data.length > 0) {
            setSelectedCard(paymentMethods.data[0].id);
        }
        setRemoveCardShowPopup(false);
    }

    const setDefaultPayment = (paymentMethodId) => {
        let values = {
            payment_method_id: paymentMethodId,
            token: user.token,
            endpoint: '/api/user/set-default-payment-method'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    setSelectedCard(paymentMethodId);
                    setPaymentMethods(response.data.paymentMethods);
                    setSelectedCard(response.data.stripeCustomer.invoice_settings.default_payment_method);
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoading(false);
        });
    }

    const handleCancelSubscriptionOpenPopup = () => setCancelSubscriptionShowPopup(true);
    const handleCancelSubscriptionClosePopup = () => setCancelSubscriptionShowPopup(false);

    const updateEmail = (event, selectedOption = 'alternate') => {
        event.preventDefault();
        if ((selectedOption === 'account' && user.alternate_email) || selectedOption === 'alternate') {
            setLoading(true);
            let values = {
                alternate_email: selectedOption === 'account' ? null : event.target.email.value,
                token: user.token,
                endpoint: '/api/user/update-user'
            };
            postRequest(values).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        toast.error(response.data.message, {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        let tempUser = response.data.user;
                        tempUser.time = user.time;
                        tempUser.token = user.token;
                        tempUser.customer_session_client_secret = user.customer_session_client_secret;
                        updateUserCookie(tempUser).then(() => {
                            updateUser(tempUser);
                            toast.success(response.data.message, {
                                position: "bottom-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        });
                    }
                } else {
                    toast.error("An error occurred!", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                setLoading(false);
            });
        }
    }

    const renewSubscription = () => {
        setLoadingRenew(true);
        let values = {
            token: user.token,
            endpoint: '/api/user-pricing-package/renew'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    window.location.reload();
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoadingRenew(false);
        });
    }

    const switchSubscription = () => {
        setLoadingRenew(true);
        let values = {
            token: user.token,
            endpoint: '/api/user-pricing-package/switch'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    toast.error(response.data.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    window.location.reload();
                }
            } else {
                toast.error("An error occurred!", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            setLoadingRenew(false);
        });
    }

    const transactionList = () => {
        let body = [];
        filteredTransactions.forEach((transaction, index) => {
            body.push(
                <tr key={"Transaction" + index}>
                    <td>
                        <div className="user-profile-card">
                            <div className="profile-card-details">
                                <h5 className="user-card-title">{user.first_name} {user.last_name}</h5>
                                <p className="user-date">Joined: {format(user.created_at, "dd MMMM, yyy | HH:mm:ss")}</p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div className="profile-card-details">
                            <h5>{transaction.user_pricing_package.pricing_package.name}</h5>
                            <p className="user-date">{transaction.reference}</p>
                        </div>
                    </td>
                    <td>{format(transaction.created_at, "MMMM dd, yyy")}</td>
                    <td>€{transaction.amount}</td>
                    <td>{transaction.is_annually ? "Annual" : "Monthly"}</td>
                    <td className="overflow-controller">
                        {transaction.status === "Paid" ?
                            <span className='paid-status'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7"
                                    fill="none">
                                    <path d="M1.2002 3.49992L3.5302 5.82992L8.19843 1.16992" stroke="#397F60"
                                        strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {transaction.status}
                            </span>
                            : <span className='cancel-status'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7"
                                    fill="none">
                                    <path d="M1.2002 5.82992L5.86019 1.16992" stroke="#E82424" strokeWidth="1.2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M5.86019 5.82992L1.2002 1.16992" stroke="#E82424" strokeWidth="1.2"
                                        strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {transaction.status}
                            </span>
                        }
                    </td>
                    <td>
                        <a href={transaction.link} target="_blank" className="view-invoice">
                            View Details
                        </a>
                    </td>
                </tr>
            );
        });
        return body;
    }

    const paymentMethodList = () => {
        let body = [];
        paymentMethods.data.forEach((data, index) => {
            body.push(
                <div key={"Payment Method " + index}
                    className={`card ${selectedCard === data.id ? 'selected' : ''}`}>
                    <div className={`card-info ${selectedCard === data.id ? 'selected' : ''}`}>
                        <div className="card-info-sub">
                            <div className='card-details-main'>
                                <div className="card-type">
                                    <img
                                        src={data.card.brand === "visa" ? visa : master}
                                        alt='cardtype'
                                    />
                                </div>
                                <div className="card-details">
                                    <p className='card-id'>**** **** **** {data.card.last4}</p>
                                    <p className='expiry'>
                                        Expiry {data.card.exp_month} / {data.card.exp_year}
                                    </p>
                                    <div className="card-actions">
                                        {paymentMethods.length > 1 &&
                                        <button
                                            onClick={() => {
                                                setSelectedPaymentMethod({card: data.card, id: data.id});
                                                setConfirmRemoveCardShowPopup(true);
                                            }}
                                            className="remove-btn">
                                            {removeCard && removeCard.id === data.card.id && loadingRemoveCard ?
                                                <span
                                                    className="spinner-grow spinner-grow-sm"
                                                    role="status"
                                                    aria-hidden="true"/>
                                                : null
                                            }
                                            Remove Card
                                        </button>
                                        }
                                        {/*<button onClick={handleEditOpenPopup}*/}
                                        {/*        className="edit-btn">Edit*/}
                                        {/*</button>*/}
                                    </div>
                                </div>
                                {selectedCard === data.id &&
                                    <span className="default-badge">Default</span>}
                            </div>
                            <label className="custom-radio">
                                <input
                                    type="radio"
                                    name="default-card"
                                    checked={selectedCard === data.id}
                                    onChange={() => setDefaultPayment(data.id)}
                                />
                                <span className="custom-radio-checkmark" />
                            </label>
                        </div>
                    </div>
                </div>
            );
        });
        return body;
    }

    if (userPricingPackage !== undefined) {
        return (
            <div className='body-main'>
                <div className='section' style={{ borderBottom: '1px solid #D6D6D6' }}>
                    <h2 className='section-title'>Billing Information</h2>
                    <p className='section-sub-title'>Update billing details and address.</p>
                </div>
                <div className='series-main-wrapper series-container'>
                    <div className='slider-set-default'>
                        <div className='billing-details-main'>
                            <div className='billing-common-section'>
                                <div className="subscription-container">
                                    <div className="subscription-container-sub">
                                        <div className="section-1-subscription">
                                            <div className="subscription-main-name">Subscription</div>
                                            <div className="subscription-sub-name">Manage your subscription</div>
                                        </div>
                                        {userPricingPackage ? (
                                            <div className="billing-common-right">
                                                <div className="current-plan-box">
                                                    <div className="plan-details-price">
                                                        <div className="plan-details">
                                                            <h4>Current Plan: {userPricingPackage.pricing_package.name}</h4>
                                                            <p>
                                                                Next payment €
                                                                {userPricingPackage.is_annually
                                                                    ? (userPricingPackage.pricing_package.annual_price * 12).toFixed(2)
                                                                    : (userPricingPackage.pricing_package.monthly_price).toFixed(2)}{' '}
                                                                due on{' '}
                                                                {format(fromUnixTime(userPricingPackage.current_period_end), 'dd MMMM, yyy')}.
                                                                Automatic renewal every{' '}
                                                                {userPricingPackage.is_annually ? 'year' : 'month'}. (VAT may apply)
                                                            </p>
                                                        </div>
                                                        <div className="switch-plan">
                                                            <div className="plan-price">
                                                                <span>
                                                                    €
                                                                    {userPricingPackage.is_annually
                                                                        ? (userPricingPackage.pricing_package.annual_price * 12).toFixed(2)
                                                                        : (userPricingPackage.pricing_package.monthly_price).toFixed(2) }
                                                                </span>
                                                                <span> / per {userPricingPackage.is_annually ? 'year' : 'month'}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="switch-plan-main">
                                                        <label
                                                            htmlFor="switch-monthly"
                                                            onClick={handleSwitchClick}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path
                                                                    d="M9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM4.95 8.4225C5.0775 7.53 5.475 6.7275 6.0975 6.0975C7.5975 4.605 9.96 4.5225 11.565 5.8275V5.115C11.565 4.8075 11.82 4.5525 12.1275 4.5525C12.435 4.5525 12.69 4.8075 12.69 5.115V7.1175C12.69 7.425 12.435 7.68 12.1275 7.68H10.125C9.8175 7.68 9.5625 7.425 9.5625 7.1175C9.5625 6.81 9.8175 6.555 10.125 6.555H10.6875C9.525 5.745 7.92 5.8575 6.885 6.8925C6.435 7.3425 6.15 7.9275 6.0525 8.58C6.015 8.8575 5.775 9.06 5.4975 9.06C5.4675 9.06 5.445 9.06 5.415 9.0525C5.1225 9.015 4.905 8.73 4.95 8.4225ZM11.9025 11.9025C11.1 12.705 10.05 13.1025 9 13.1025C8.085 13.1025 7.1775 12.78 6.4275 12.1725V12.8775C6.4275 13.185 6.1725 13.44 5.865 13.44C5.5575 13.44 5.3025 13.185 5.3025 12.8775V10.875C5.3025 10.5675 5.5575 10.3125 5.865 10.3125H7.8675C8.175 10.3125 8.43 10.5675 8.43 10.875C8.43 11.1825 8.175 11.4375 7.8675 11.4375H7.305C8.4675 12.2475 10.0725 12.135 11.1075 11.1C11.5575 10.65 11.8425 10.065 11.94 9.4125C11.985 9.105 12.2625 8.8875 12.5775 8.9325C12.885 8.9775 13.095 9.2625 13.0575 9.57C12.9225 10.4775 12.525 11.28 11.9025 11.9025Z"
                                                                    fill="#25242F"/>
                                                            </svg>
                                                            Switch to{' '}
                                                            {userPricingPackage.is_annually ? 'monthly' : 'yearly'} (€{!userPricingPackage.is_annual
                                                            ? userPricingPackage.pricing_package.annual_price
                                                            : userPricingPackage.pricing_package.monthly_price}{' '}
                                                            / per month)
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='billing-page-sub-btn-main'>
                                                    {userPricingPackage.active ?
                                                        <>
                                                            <button className="cancel-button"
                                                                    onClick={handleCancelSubscriptionOpenPopup}>Cancel
                                                                Subscription
                                                            </button>
                                                            {showCancelSubscriptionPopup &&
                                                            <CancelSubscriptionPopUp
                                                                onClose={handleCancelSubscriptionClosePopup}
                                                                user={user}/>}
                                                        </>
                                                        : <button className="renew-button"
                                                                  onClick={() => renewSubscription()}>
                                                            {loadingRenew ?
                                                                <span className="spinner-grow spinner-grow-sm"
                                                                      role="status"
                                                                      aria-hidden="true"/>
                                                                : null
                                                            }
                                                            Renew Subscription
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="subscription-sub-name">
                                                You have not subscribed to any package.
                                            </div>
                                        )}
                                    </div>
                                    {showConfirmationPopup && (
                                        <div className="confirmation-popup">
                                            <div className="popup-content">
                                                <p>Are you sure you want to switch plans?</p>
                                                <div className="popup-actions">
                                                    <button onClick={confirmSwitchPlan} className="plan-confirm-btn">
                                                        Yes
                                                    </button>
                                                    <button onClick={cancelSwitchPlan} className="plan-cancel-btn">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="subscription-container">
                                    <div className='subscription-container-sub'>
                                        <div>
                                            <div className='subscription-main-name'>
                                                Contact Email
                                            </div>
                                            <div className='subscription-sub-name'>
                                                Where should the invoices be sent?
                                            </div>
                                        </div>
                                        <div className='billing-common-right'>
                                            <div className="email-option">
                                                <label className="custom-radio-email">
                                                    <input
                                                        type="radio"
                                                        id="account-email"
                                                        name="email-option"
                                                        value="account"
                                                        checked={selectedOption === 'account'}
                                                        onChange={(event) => {
                                                            setSelectedOption('account');
                                                            updateEmail(event, 'account');
                                                        }}
                                                    />
                                                    <span className="custom-radio-checkmark-email" />
                                                </label>
                                                <label htmlFor="account-email">
                                                    <span
                                                        className='email-sending-topic'>Send to my account email</span>
                                                    <span className="email-display">{user.email}</span>
                                                </label>
                                            </div>

                                            <div className="email-option">
                                                <label className="custom-radio-email">
                                                    <input
                                                        type="radio"
                                                        id="alternate-email"
                                                        name="email-option"
                                                        value="alternate"
                                                        checked={selectedOption === 'alternate'}
                                                        onChange={() => setSelectedOption('alternate')}
                                                    />
                                                    <span className="custom-radio-checkmark-email" />
                                                </label>
                                                <label htmlFor="alternate-email">
                                                    <span
                                                        className='email-sending-topic'>Send to an alternate email</span>
                                                </label>
                                            </div>

                                            {selectedOption === 'alternate' && (
                                                <form onSubmit={updateEmail}>
                                                    <div className='input-mail-logo-wrapper'>
                                                        <div className='input-mail-logo'>
                                                            <input
                                                                type="email"
                                                                id="email"
                                                                name="email"
                                                                placeholder="janetlawrence@email.com"
                                                                defaultValue={user.alternate_email}
                                                                className='billing-email'
                                                            />
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                viewBox="0 0 16 16" fill="none">
                                                                <path xmlns="http://www.w3.org/2000/svg"
                                                                    d="M11.334 2.3335H4.66732C2.66732 2.3335 1.33398 3.3335 1.33398 5.66683V10.3335C1.33398 12.6668 2.66732 13.6668 4.66732 13.6668H11.334C13.334 13.6668 14.6673 12.6668 14.6673 10.3335V5.66683C14.6673 3.3335 13.334 2.3335 11.334 2.3335ZM11.6473 6.3935L9.56065 8.06016C9.12065 8.4135 8.56065 8.58683 8.00065 8.58683C7.44065 8.58683 6.87398 8.4135 6.44065 8.06016L4.35398 6.3935C4.14065 6.22016 4.10732 5.90016 4.27398 5.68683C4.44732 5.4735 4.76065 5.4335 4.97398 5.60683L7.06065 7.2735C7.56732 7.68016 8.42732 7.68016 8.93398 7.2735L11.0207 5.60683C11.234 5.4335 11.554 5.46683 11.7207 5.68683C11.894 5.90016 11.8607 6.22016 11.6473 6.3935Z"
                                                                    fill="#CCCCCC" />
                                                            </svg>
                                                        </div>
                                                        <button className="renew-button" type="submit">
                                                            {loading ?
                                                                <span className="spinner-grow spinner-grow-sm" role="status"
                                                                    aria-hidden="true" />
                                                                : null
                                                            }
                                                            Update
                                                        </button>
                                                    </div>
                                                </form>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="subscription-container">
                                    <div className='subscription-container-sub'>
                                        <div>
                                            <div className='subscription-main-name'>
                                                Payment method
                                            </div>
                                            <div className='subscription-sub-name'>
                                                Manage your payment methods
                                            </div>
                                        </div>
                                        <div className='billing-common-right'>
                                            {!loadingPaymentMethods ?
                                                paymentMethodList()
                                                : <Loading/>
                                            }
                                            {showConfirmRemoveCardPopup && selectedPaymentMethod && (
                                                <div className="confirmation-popup">
                                                    <div className="popup-content">
                                                        <p>Are you sure you want to remove card?</p>
                                                        <div className="popup-actions">
                                                            <button
                                                                onClick={() => handleRemoveCardOpenPopup(selectedPaymentMethod.card, selectedPaymentMethod.id)}
                                                                className="plan-cancel-btn">
                                                                Yes
                                                            </button>
                                                            <button onClick={() => {
                                                                setConfirmRemoveCardShowPopup(false);
                                                                setSelectedPaymentMethod(null);
                                                            }} className="plan-confirm-btn">
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {showRemoveCardPopup &&
                                            <CardRemovedPopup onClose={handleRemoveCardClosePopup} card={removeCard}/>
                                            }
                                            {showEditPopup && <EditNewPaymentMethod onClose={handleEditClosePopup}/>}
                                            <div className="add-payment-method">
                                                <button onClick={handleOpenPopup} className="add-method-btn">+ Add new
                                                    payment method
                                                </button>
                                                {showPopup && <SetupPayment onClose={handleClosePopup}/>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row-main-container ">
                            <div className="col">
                                <div className="form-group">
                                    <label className='table-main-topic'>Billing History</label>
                                    <div className="filters filter2">
                                        <div>
                                            <label>Search for invoice ID</label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input className="course-input" type="text" id="invoice_id"
                                                    name="invoice_id" placeholder="Search by invoice id"
                                                    value={searchInvoice}
                                                    onChange={(e) => setSearchInvoice(e.target.value)} />
                                                <svg style={{ marginLeft: -50 + "px" }} xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path
                                                        d="M7.3335 13.334C10.6472 13.334 13.3335 10.6477 13.3335 7.33398C13.3335 4.02028 10.6472 1.33398 7.3335 1.33398C4.01979 1.33398 1.3335 4.02028 1.3335 7.33398C1.3335 10.6477 4.01979 13.334 7.3335 13.334Z"
                                                        stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                    <path
                                                        d="M12.6201 13.7939C12.9734 14.8605 13.7801 14.9672 14.4001 14.0339C14.9668 13.1805 14.5934 12.4805 13.5668 12.4805C12.8068 12.4739 12.3801 13.0672 12.6201 13.7939Z"
                                                        stroke="#CCCCCC" strokeWidth="1.5" strokeLinecap="round"
                                                        strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </div>

                                        <div className="drp-fil0">
                                            <label>Filter By Status</label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <select className="filter-option" value={selectedStatus}
                                                    onChange={(e) => setSelectedStatus(e.target.value)}>
                                                    <option value="">All</option>
                                                    <option value="Paid">Paid</option>
                                                    <option value="Failed">Failed</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div>
                                            <label>Filter By Date</label>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="add-delete-btn">
                                                    <div className="date-picker spc-d-pic data-p-2-spc">
                                                        <div className="date-picker__date-selection spc-d-pic"
                                                            onClick={handleClick}>
                                                            <i className="far fa-calendar-alt" />
                                                            <div className="relative-date-picker">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    className="left-svg"
                                                                    width="16" height="16" viewBox="0 0 16 16"
                                                                    fill="none">
                                                                    <path d="M5.33203 1.33301V3.33301" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeMiterlimit="10"
                                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M10.668 1.33301V3.33301" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeMiterlimit="10"
                                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path d="M2.33203 6.05957H13.6654" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeMiterlimit="10"
                                                                        strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path
                                                                        d="M14 5.66634V11.333C14 13.333 13 14.6663 10.6667 14.6663H5.33333C3 14.6663 2 13.333 2 11.333V5.66634C2 3.66634 3 2.33301 5.33333 2.33301H10.6667C13 2.33301 14 3.66634 14 5.66634Z"
                                                                        stroke="#CCCCCC" strokeWidth="1.2"
                                                                        strokeMiterlimit="10" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M10.4625 9.13338H10.4685" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M10.4625 11.1334H10.4685" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M7.99764 9.13338H8.00363" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M7.99764 11.1334H8.00363" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M5.52889 9.13338H5.53488" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                    <path d="M5.52889 11.1334H5.53488" stroke="#CCCCCC"
                                                                        strokeWidth="1.2" strokeLinecap="round"
                                                                        strokeLinejoin="round" />
                                                                </svg>
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    className="rgt-svg"
                                                                    width="14" height="14" viewBox="0 0 14 14"
                                                                    fill="none">
                                                                    <path
                                                                        d="M10.4539 5.00012H6.81975H3.54725C2.98725 5.00012 2.70725 5.67679 3.10392 6.07346L6.12559 9.09512C6.60975 9.57929 7.39725 9.57929 7.88142 9.09512L9.03059 7.94595L10.9031 6.07346C11.2939 5.67679 11.0139 5.00012 10.4539 5.00012Z"
                                                                        fill="#CCCCCC" />
                                                                </svg>
                                                                <input
                                                                    className="date-picker__input"
                                                                    type="text"
                                                                    placeholder="Select Date"
                                                                    value={selectedDate ? format(selectedDate, dateFormat) : ''}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                        {isCalendarOpen && (
                                                            <DatePicker
                                                                selected={selectedDate || new Date()}
                                                                onChange={handleDateChange}
                                                                dateFormat={dateFormat}
                                                                inline
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-container-table ">
                            <table className="table-main spc-price-tbl">
                                <thead className="table-head">
                                    <tr>
                                        <th className="table-details">Invoice</th>
                                        <th>Plan</th>
                                        <th>Billed Date</th>
                                        <th>Amount</th>
                                        <th>Plan Type</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <span className="table-main d-block" />
                                <tbody>
                                    {transactionList()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <Loading />
        );
    }
}
