import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import tick from "../assets/images/tick.svg";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../assets/css/PricesPage.css';
import '../assets/css/Home.css';
import { loadPricingPackages } from "../common/Common";
import Loading from "./Loading";
import { createSelectedPricingPackage } from "../auth/Auth";

function PriceCards() {
    const [isOn, setIsOn] = useState(false),
          [pricingPackages, setPricingPackages] = useState([]),
          [loading, setLoading] = useState(true),
          [isMobileOrTablet, setIsMobileOrTablet] = useState(window.innerWidth < 992);

    useEffect(() => {
        loadPricingPackages().then((data) => {
            setPricingPackages(data);
            setLoading(false);
        }).catch(() => null);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileOrTablet(window.innerWidth < 992);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const priceSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const navigate = useNavigate();

    const handleClick = (pricingPackage, isAnnual) => {
        createSelectedPricingPackage({ ...pricingPackage, annualSelected: isAnnual }).then(() => {
            navigate('/checkout');
        });
    };

    const pricingPackageList = () => {
        if (pricingPackages.length === 1) {
            const pricingPackage = pricingPackages[0];
            return (
                <>
                    {/* Monthly Card */}
                    <div className='section-2-price-content-sub'>
                        <div className='section-2-price-content'>
                            <h2>{pricingPackage.name} - Monthly</h2>
                            <p>
                                <span className='price-tag'>€{pricingPackage.monthly_price}</span> / per month
                            </p>
                            <p className="textarea-description">{pricingPackage.description}</p>
                            <button className='subscribe' onClick={() => handleClick(pricingPackage, false)}>Sign up Now!</button>
                            {pricingPackage.benefits.map((benefit, index) => (
                                <p key={"BenefitMonthly" + index}>
                                    <img src={tick} alt='tick' /> {benefit}
                                </p>
                            ))}
                        </div>
                    </div>
                    {/* Annual Card */}
                    <div className='section-2-price-content-sub'>
                        <div className='section-2-price-content'>
                            <h2>{pricingPackage.name} - Yearly</h2>
                            <p>
                                <span className='price-tag'>€{pricingPackage.annual_price}</span> / per month
                            </p>
                            <p className="textarea-description">{pricingPackage.description}</p>
                            <button className='subscribe' onClick={() => handleClick(pricingPackage, true)}>Sign up Now!</button>
                            {pricingPackage.benefits.map((benefit, index) => (
                                <p key={"BenefitAnnual" + index}>
                                    <img src={tick} alt='tick' /> {benefit}
                                </p>
                            ))}
                        </div>
                    </div>
                </>
            );
        }

        return pricingPackages.map((pricingPackage, index) => (
            <div className='section-2-price-content-sub' key={"PricingPackage" + index}>
                <div className='section-2-price-content'>
                    <h2>{pricingPackage.name}</h2>
                    <p>
                        <span className='price-tag'>
                            €{isOn ? pricingPackage.annual_price : pricingPackage.monthly_price}
                        </span> / per month
                    </p>
                    <p className="textarea-description">{pricingPackage.description}</p>
                    <button className='subscribe' onClick={() => handleClick(pricingPackage, isOn)}>Sign up Now!</button>
                    {pricingPackage.benefits.map((benefit, index) => (
                        <p key={"Benefit" + index + "" + pricingPackage.id}>
                            <img src={tick} alt='tick' /> {benefit}
                        </p>
                    ))}
                </div>
            </div>
        ));
    };

    const mobilePricingPackageList = () => {
        return pricingPackages.map((pricingPackage, index) => (
            <div className='section-2-price-content-main' key={"PricingPackage" + index}>
                <div className='section-2-price-content-sub'>
                    <div className='section-2-price-content'>
                        <h2>{pricingPackage.name}</h2>
                        <p>
                            <span className='price-tag'>
                                €{isOn ? pricingPackage.annual_price : pricingPackage.monthly_price}
                            </span> / per month
                        </p>
                        <p>{pricingPackage.description}</p>
                        <button className='subscribe' onClick={() => handleClick(pricingPackage, isOn)}>Book a class today!</button>
                        {pricingPackage.benefits.map((benefit, index) => (
                            <p key={"Benefit" + index + "" + pricingPackage.id}>
                                <img src={tick} alt='tick' /> {benefit}
                            </p>
                        ))}
                    </div>
                </div>
            </div>
        ));
    };

    if (!loading) {
        return (
            <div className='price-main'>
                <div className='price-container spc-container'>
                    <div className='section-2-price'>
                        <div className='section-2-price-details'>
                            <h1>Select the Plan that Fits You.</h1>
                            <p>Join our community and explore our membership options. Find the perfect plan to fit your lifestyle.</p>
                            {(isMobileOrTablet || pricingPackages.length > 1) && (
                                <div className='duration'>
                                    <h4>Monthly</h4>
                                    <div className={`toggle-switch ${isOn ? 'on' : 'off'}`} onClick={toggleSwitch}>
                                        <div className="toggle-thumb" />
                                    </div>
                                    <h4>Annual</h4>
                                </div>
                            )}
                        </div>
                        <div className="desktop-view">
                            <div className='section-2-price-content-main'>
                                {pricingPackageList()}
                            </div>
                        </div>
                    </div>

                    <div className="mobile-view">
                        <Slider {...priceSettings}>
                            {mobilePricingPackageList()}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <Loading />
        );
    }
}

export default PriceCards;
