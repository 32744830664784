import React, {useEffect, useState} from 'react';
import {loadContactDetails, loadLegals} from "../common/Common";
import {Link, useLocation} from "react-router-dom";
import '../assets/css/Footer.css';
import logo from "../assets/images/logo-dark.svg";
import {postRequest} from "../routes/Routes";

function Footer() {
    const location = useLocation();
    const [contacts, setContacts] = useState(null);
    const [legals, setLegals] = useState([]);
    const [error, setError] = useState({});
    const [success, setSuccess] = useState({});
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        loadLegals().then((data) => {
            setLegals(data);
        }).catch(() => null);
        loadContactDetails().then((data) => {
            setContacts(data);
        }).catch(() => null);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoadingSubmit(true);
        let values = {
            first_name: event.target.first_name.value,
            email: event.target.email.value,
            message: event.target.message.value,
            endpoint: '/api/contact-us'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    setError(values => ({...values, error: false, errorMessage: null}));
                    setSuccess(values => ({...values, success: true, successMessage: response.data.message}));
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoadingSubmit(false);
        });
    };

    return (
        <div className='main-container-footer'>
            {location.pathname !== "/contact-us" && (
                <div className='section-main container-footer'>
                    <div className='section-1 logo'>
                        <h2>Get in touch</h2>
                        <h4 className='main-intro-footer'>
                            Email Aaron at <a href='mailto:contact@pilates-corner.com'>contact@pilates-corner.com</a> or fill out the contact form here and we will answer any of your queries.
                        </h4>
                    </div>
                    <div className='section-1 content'>
                        <div className='section-1-footer-contact'>
                            <form onSubmit={handleSubmit}>
                                <div className='details-main' style={{ display: 'flex', gap: '20px' }}>
                                    <div className='details-sub'>
                                        <input
                                            className="contact-input content-input-ftr"
                                            id="first_name"
                                            name="first_name"
                                            placeholder="Full Name"
                                            required
                                        />
                                    </div>
                                    <div className='details-sub'>
                                        <input
                                            className="contact-input content-input-ftr"
                                            id="email"
                                            name="email"
                                            placeholder="Email Address"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className='details-main details-main-ftr'>
                                    <div className='details-sub'>
                                        <textarea
                                            className="footer-contact-input-textarea"
                                            id="message"
                                            name="message"
                                            placeholder="Message..."
                                            required
                                        />
                                    </div>

                                    <div>
                                        <button type="submit" className='section-2-contact-button'>
                                            {loadingSubmit ?
                                                <span className="spinner-grow spinner-grow-sm" role="status"
                                                    aria-hidden="true"/>
                                                : null
                                            }
                                            Send Message
                                        </button>
                                    </div>
                                </div>
                                {error.error &&
                                    <div className="alert alert-danger" role="alert">
                                        {error.errorMessage}
                                    </div>}
                                    {success.success &&
                                    <div className="alert alert-success" role="alert">
                                        {success.successMessage}
                                    </div>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            )}

            <div className='section-main2 container-footer'>
                <div>
                    <img src={logo} alt='Logo' className="footer-logo"/>
                    <div className='social-footer'>
                        <a href="https://www.facebook.com/share/1XkNXu4mJH/?mibextid=LQQJ4d" target='_blank'>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" x="0" y="0"
                                 viewBox="0 0 512 512">
                                <g>
                                    <path fill-rule="evenodd"
                                          d="M255.182 7.758q69.23.79 125.086 34.03a249.734 249.734 0 0 1 88.89 89.434q33.037 56.191 33.825 125.843-1.962 95.3-60.117 162.79c-38.77 44.995-88.425 72.83-139.827 83.501V325.23h48.597l10.99-70h-73.587v-45.848a39.844 39.844 0 0 1 8.474-26.323q8.827-11.253 31.09-11.829h44.436v-61.318q-.957-.308-18.15-2.434a360.743 360.743 0 0 0-39.16-2.434q-44.433.205-70.281 25.068-25.85 24.855-26.409 71.92v53.198h-56v70h56v178.127c-63.115-10.67-112.77-38.506-151.54-83.5S8.691 320.598 7.383 257.065q.785-69.655 33.824-125.843a249.739 249.739 0 0 1 88.891-89.435q55.854-33.233 125.084-34.03z"
                                          fill="#25242f" opacity="1" data-original="#000000" class=""></path>
                                </g>
                            </svg>
                        </a>
                        <a href="https://www.instagram.com/the.pilatescorner" target='_blank'>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" x="0" y="0"
                                 viewBox="0 0 512 512">
                                <g>
                                    <path
                                        d="M305 256c0 27.063-21.938 49-49 49s-49-21.938-49-49 21.938-49 49-49 49 21.938 49 49zm0 0"
                                        fill="#25242f" opacity="1" data-original="#000000" class="hovered-path"></path>
                                    <path
                                        d="M370.594 169.305a45.546 45.546 0 0 0-10.996-16.903 45.514 45.514 0 0 0-16.903-10.996c-5.18-2.011-12.96-4.406-27.293-5.058-15.504-.707-20.152-.86-59.402-.86-39.254 0-43.902.149-59.402.856-14.332.656-22.118 3.05-27.293 5.062a45.483 45.483 0 0 0-16.903 10.996 45.572 45.572 0 0 0-11 16.903c-2.011 5.18-4.406 12.965-5.058 27.297-.707 15.5-.86 20.148-.86 59.402 0 39.25.153 43.898.86 59.402.652 14.332 3.047 22.114 5.058 27.293a45.563 45.563 0 0 0 10.996 16.903 45.514 45.514 0 0 0 16.903 10.996c5.18 2.015 12.965 4.41 27.297 5.062 15.5.707 20.144.856 59.398.856 39.258 0 43.906-.149 59.402-.856 14.332-.652 22.118-3.047 27.297-5.062a48.68 48.68 0 0 0 27.899-27.899c2.011-5.18 4.406-12.96 5.062-27.293.707-15.504.856-20.152.856-59.402 0-39.254-.149-43.902-.856-59.402-.652-14.332-3.047-22.118-5.062-27.297zM256 331.485c-41.691 0-75.488-33.794-75.488-75.485s33.797-75.484 75.488-75.484c41.688 0 75.484 33.793 75.484 75.484S297.688 331.484 256 331.484zm78.469-136.313c-9.742 0-17.64-7.899-17.64-17.64s7.898-17.641 17.64-17.641 17.64 7.898 17.64 17.64c-.004 9.742-7.898 17.64-17.64 17.64zm0 0"
                                        fill="#25242f" opacity="1" data-original="#000000" class="hovered-path"></path>
                                    <path
                                        d="M256 0C114.637 0 0 114.637 0 256s114.637 256 256 256 256-114.637 256-256S397.363 0 256 0zm146.113 316.605c-.71 15.649-3.199 26.333-6.832 35.684a75.164 75.164 0 0 1-42.992 42.992c-9.348 3.633-20.035 6.117-35.68 6.832-15.675.715-20.683.887-60.605.887-39.926 0-44.93-.172-60.61-.887-15.644-.715-26.331-3.199-35.68-6.832a72.018 72.018 0 0 1-26.038-16.957 72.044 72.044 0 0 1-16.953-26.035c-3.633-9.348-6.121-20.035-6.832-35.68-.723-15.68-.891-20.687-.891-60.609s.168-44.93.887-60.605c.71-15.649 3.195-26.332 6.828-35.684a72.013 72.013 0 0 1 16.96-26.035 72.003 72.003 0 0 1 26.036-16.957c9.352-3.633 20.035-6.117 35.684-6.832C211.07 109.172 216.078 109 256 109s44.93.172 60.605.89c15.649.712 26.332 3.196 35.684 6.825a72.061 72.061 0 0 1 26.04 16.96 72.027 72.027 0 0 1 16.952 26.036c3.637 9.352 6.121 20.035 6.836 35.684.715 15.675.883 20.683.883 60.605s-.168 44.93-.887 60.605zm0 0"
                                        fill="#25242f" opacity="1" data-original="#000000" class="hovered-path"></path>
                                </g>
                            </svg>
                        </a>
                        {/*<a href="" target='_blank'>*/}
                        {/*    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" x="0" y="0" viewBox="0 0 152 152" ><g><g data-name="Layer 2"><g data-name="02.YouTube"><path d="m65.46 88.26 21.08-12.23-21.08-12.29z" fill="#25242f" opacity="1" data-original="#000000" class="hovered-path"></path><path d="M76 0a76 76 0 1 0 76 76A76 76 0 0 0 76 0zm40 89.45a15.13 15.13 0 0 1-15.13 15.14H51.13A15.13 15.13 0 0 1 36 89.45v-26.9a15.13 15.13 0 0 1 15.13-15.14h49.74A15.13 15.13 0 0 1 116 62.55z" fill="#25242f" opacity="1" data-original="#000000" class="hovered-path"></path></g></g></g></svg>*/}
                        {/*</a>*/}
                    </div>
                </div>
                <div className='section-2 links'>
                    <h5>Quick Links</h5>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/series">Classes</Link></li>
                        <li><Link to="/courses">Courses</Link></li>
                        <li><Link to="/contact-us">Connect</Link></li>
                        <li><Link to="/about-us">About</Link></li>
                    </ul>
                </div>
                <div className='section-2 legal'>
                    <h5>Terms & Conditions</h5>
                    <ul>
                        {legals.map((legal) => (
                            <li key={legal.id}>
                                <Link to={'/legal'}>{legal.heading}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='section-2 contact con-detail'>
                    <h5>Contact Details</h5>
                    <ul>
                        {contacts && contacts.length > 0 ? (
                            contacts.map((contact) => (
                                <React.Fragment key={contact.id}>
                                    <li>
                                        {contact.title}
                                        <a
                                            href={contact.value.includes('@') ? `mailto:${contact.value}` : `tel:${contact.value}`}
                                        >
                                            {contact.value}
                                        </a>
                                    </li>
                                </React.Fragment>
                            ))
                        ) : (
                            <li>No contact details available.</li>
                        )}
                    </ul>
                </div>
            </div>

            <div className='section-main3 container-footer'>
                <div className='section-3 term'>
                    © {currentYear} The Pilates Corner | All rights reserved
                </div>
                <div className='section-3 term'>
                    Designed & Developed by <a href="https://yellawt.com" target='_blank' style={{color:"unset", textDecoration:"none"}}>Yellawt</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
