import React, { useState, useEffect } from "react";
import '../assets/css/AddNewPaymentMethod.css';
import CardChangesSaved from './CardChangesSaved';

const EditNewPaymentMethod = () => {
    const [showSaveChangesCardPoup, setSaveChangesCardPopup] = useState(false);


    const handleChangesSaveCardOpenPopup = () => setSaveChangesCardPopup(true);
    const handleChangesSaveCardClosePopup = () => setSaveChangesCardPopup(false);

    return (
        <div className="AddNewPaymentMethod-wrapper">
            <div className="EditNewPaymentMethod-wrapper">
                <form className="profile-checkout-form">
                    <div className="profile-checkout-card-details">
                        <div className='checkout-txt-box'>
                            <span className='main-box-checkout'>Edit Payment Method</span>
                            <span className='sub-box-checkout'>Add you card payment details</span>
                        </div>
                        <label>Card Holder Name</label>
                        <input
                            type="text"

                            // onChange={(e) => setCardName(e.target.value)}
                            className="profile-checkout-input"
                            placeholder='Janet Lawrence'
                        />
                        <label>Card Number</label>
                        <input
                            type="text"

                            // onChange={(e) => setCardNumber(e.target.value)}
                            className="profile-checkout-input"
                            placeholder='9898 4567 7869 1234'
                        />
                        <div className='wrapper-secure-info'>
                            <div>
                                <label>CVV Code</label>
                                <input
                                    type="password"

                                    // onChange={(e) => setCvv(e.target.value)}
                                    className="profile-checkout-input"
                                    placeholder='000'
                                />
                            </div>
                            <div>
                                <label>Expiry Date</label>
                                <input
                                    type="text"
                                    placeholder="MM / YYYY"

                                    // onChange={(e) => setExpiryDate(e.target.value)}
                                    className="profile-checkout-input"
                                />
                            </div>

                        </div>

                    </div>
                    <div className="addcard-flx">
                        <button type="submit" className="profile-not-checkout-submit-btn">Cancel</button>
                        <button type="submit" onClick={handleChangesSaveCardOpenPopup} className="profile-checkout-submit-btn">Edit card</button>
                        {showSaveChangesCardPoup && <CardChangesSaved onClose={handleChangesSaveCardClosePopup} />}
                    </div>

                </form>
            </div>
        </div>
    );
};

export default EditNewPaymentMethod;