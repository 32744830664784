import React, {useEffect, useState} from "react";
import '../assets/css/OtpPopUp.css';
import {postRequest} from "../routes/Routes";
import {createEmail} from "../auth/Auth";

const OtpPopup = ({email, onClose, resendOtp}) => {
    const [timeLeft, setTimeLeft] = useState(300),
        [otp, setOtp] = useState(new Array(6).fill("")),
        [isOtpComplete, setIsOtpComplete] = useState(false),
        [loading, setLoading] = useState(false),
        [error, setError] = useState({});

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft]);

    useEffect(() => {
        setIsOtpComplete(otp.every((digit) => digit !== ""));
    }, [otp]);

    const handleOtpChange = (event, index, isPaste) => {
        isPaste && event.preventDefault();
        const value = (isPaste ? event.clipboardData.getData('Text') : event.target.value).replace(/[^0-9]/g, "");
        if (value) {
            const valueArray = value.toString().split("").map(Number);
            const newOtp = [...otp];
            if (valueArray.length > 1) {
                if (isPaste) {
                    newOtp.map((data, index) => {
                        newOtp[index] = valueArray[index] || valueArray[index] === 0 ? valueArray[index] : otp[index];
                    });
                } else {
                    if (newOtp[index] === valueArray[0]) {
                        newOtp[index] = valueArray[valueArray.length - 1];
                    } else {
                        newOtp[index] = valueArray[0];
                    }
                }
            } else {
                newOtp[index] = valueArray[0];
            }
            setOtp(newOtp);
        } else {
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);
        }
    };

    const handleOtpVerification = (event) => {
        event.preventDefault();
        setLoading(true);
        let values = {
            email: email,
            code: String(parseInt(otp.join(''))).padStart(6, '0'),
            endpoint: '/api/otp/validate'
        };
        postRequest(values).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    setError(values => ({...values, error: true, errorMessage: response.data.message}));
                } else {
                    createEmail(email).then(() => {
                        setError(values => ({...values, error: false, errorMessage: null}));
                        window.location.href = 'signup/';
                    });
                }
            } else {
                setError(values => ({...values, error: true, errorMessage: "An error occurred!"}));
            }
            setLoading(false);
        });
    };

    return (
        <div className="otp-popup">
            <div className="otp-popup-content">
                <span className="close-btn" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <g id="vuesax/bold/close-circle">
                            <g id="close-circle">
                                <path id="Vector"
                                      d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM19.2 17.875C19.5625 18.2375 19.5625 18.8375 19.2 19.2C19.0125 19.3875 18.775 19.475 18.5375 19.475C18.3 19.475 18.0625 19.3875 17.875 19.2L15 16.325L12.125 19.2C11.9375 19.3875 11.7 19.475 11.4625 19.475C11.225 19.475 10.9875 19.3875 10.8 19.2C10.4375 18.8375 10.4375 18.2375 10.8 17.875L13.675 15L10.8 12.125C10.4375 11.7625 10.4375 11.1625 10.8 10.8C11.1625 10.4375 11.7625 10.4375 12.125 10.8L15 13.675L17.875 10.8C18.2375 10.4375 18.8375 10.4375 19.2 10.8C19.5625 11.1625 19.5625 11.7625 19.2 12.125L16.325 15L19.2 17.875Z"
                                      fill="#A6A6A6"/>
                            </g>
                        </g>
                    </svg>
                </span>
                <h2>Check Your Email For Code</h2>
                <p className="popup-details">
                    We've sent a 6-character code to your email <span> <strong>{email}</strong></span>.
                    The code expires shortly, so please enter it soon.
                </p>
                <p className="enter-otp">Enter OTP Code</p>
                <div className="otp-input-container">
                    {otp.map((data, index) => (
                        <input
                            key={index}
                            type="text"
                            className="otp-input"
                            value={data}
                            onChange={(e) => handleOtpChange(e, index, false)}
                            onFocus={(e) => e.target.select()}
                            onPaste={(e) => handleOtpChange(e, index, true)}
                            onKeyUp={(e) => e.key.replace(/[^0-9]/g, "") ? e.target.nextSibling ? e.target.nextSibling.focus() : e.target.parentNode.firstChild.focus() : null}
                            placeholder="0"
                        />
                    ))}
                </div>
                <p className="timer">
                    Remaining time:{" "}
                    <span>
                        {(timeLeft / 60) < 10 ? (`0${Math.floor(timeLeft / 60)}`) : Math.floor(timeLeft / 60)}:{(timeLeft % 60) < 10 ? (`0${timeLeft % 60}`) : (timeLeft % 60)}
                    </span>
                </p>
                {error.error &&
                <div className="alert alert-danger" role="alert">
                    {error.errorMessage}
                </div>}
                {isOtpComplete && (
                    <button className="confirm-btn" onClick={handleOtpVerification}>
                        {loading ?
                            <span className="spinner-grow spinner-grow-sm" role="status"
                                  aria-hidden="true"/>
                            : null
                        }
                        Confirm
                    </button>
                )}

                <p className="resend-otp">
                    Can't find the code? Check your spam folder or{" "}
                    <a href="" onClick={(event) => {
                        resendOtp(event);
                        setTimeLeft(300);
                    }}>
                        resend OTP.
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default OtpPopup;
