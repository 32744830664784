import React, {useState} from 'react';
import '../assets/css/CancelSubscriptionPopUp.css';
import CancelSubscriptionPopUp from './UnsubscribePopup';

const CancelSubscription = ({onClose, user}) => {
    const [showCancelSubscriptionSuccessPopup, setCancelSubscriptionSuccessShowPopup] = useState(false);

    const handleCancelSubscriptionSuccessOpenPopup = () => setCancelSubscriptionSuccessShowPopup(true);
    const handleCancelSubscriptionSuccessClosePopup = () => setCancelSubscriptionSuccessShowPopup(false);

    return (
        <div className="popup-overlay-CancelSubscription">
            <div className="popup-container-CancelSubscription">
                <button className="close-button" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                            d="M15 2.5C8.1125 2.5 2.5 8.1125 2.5 15C2.5 21.8875 8.1125 27.5 15 27.5C21.8875 27.5 27.5 21.8875 27.5 15C27.5 8.1125 21.8875 2.5 15 2.5ZM19.2 17.875C19.5625 18.2375 19.5625 18.8375 19.2 19.2C19.0125 19.3875 18.775 19.475 18.5375 19.475C18.3 19.475 18.0625 19.3875 17.875 19.2L15 16.325L12.125 19.2C11.9375 19.3875 11.7 19.475 11.4625 19.475C11.225 19.475 10.9875 19.3875 10.8 19.2C10.4375 18.8375 10.4375 18.2375 10.8 17.875L13.675 15L10.8 12.125C10.4375 11.7625 10.4375 11.1625 10.8 10.8C11.1625 10.4375 11.7625 10.4375 12.125 10.8L15 13.675L17.875 10.8C18.2375 10.4375 18.8375 10.4375 19.2 10.8C19.5625 11.1625 19.5625 11.7625 19.2 12.125L16.325 15L19.2 17.875Z"
                            fill="#A6A6A6"/>
                    </svg>
                </button>
                <div className="icon-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <path
                            d="M54.9988 14.275V38.225C54.9988 45.125 49.3988 50.725 42.4988 50.725H17.4988C16.3488 50.725 15.2488 50.575 14.1738 50.275C12.6238 49.85 12.1238 47.875 13.2738 46.725L39.8488 20.15C40.3988 19.6 41.2238 19.475 41.9988 19.625C42.7988 19.775 43.6738 19.55 44.2988 18.95L50.7238 12.5C53.0738 10.15 54.9988 10.925 54.9988 14.275Z"
                            fill="#F65C5C"/>
                        <path
                            d="M36.6 18.4L10.425 44.575C9.225 45.775 7.225 45.475 6.425 43.975C5.5 42.275 5 40.3 5 38.225V14.275C5 10.925 6.925 10.15 9.275 12.5L15.725 18.975C16.7 19.925 18.3 19.925 19.275 18.975L28.225 9.99999C29.2 9.02499 30.8 9.02499 31.775 9.99999L36.625 14.85C37.575 15.825 37.575 17.425 36.6 18.4Z"
                            fill="#F65C5C"/>
                    </svg>
                </div>
                <h2 className='CancelSubscription-popup-main'>Are you sure you want to cancel the current subscription
                    plan?</h2>
                <p className='CancelSubscription-popup-sub'>Please note that by cancelling your subscription, you will
                    lose all the features that you are currently using. </p>
                <div className='CancelSubscription-button-main'>
                    <button className="CancelSubscription-ok-button" onClick={onClose}>Keep Subscription Plan</button>
                    <button className="CancelSubscription-cancel-button"
                            onClick={handleCancelSubscriptionSuccessOpenPopup}>Continue cancellation
                    </button>
                    {showCancelSubscriptionSuccessPopup &&
                    <CancelSubscriptionPopUp onClose={handleCancelSubscriptionSuccessClosePopup} user={user}/>}
                </div>
            </div>
        </div>
    );
};

export default CancelSubscription;
